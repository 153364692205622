.floating-chat {
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 1000;
  }
  
  .chat-toggle {
    border: gold;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    font-size: 10px;
    background-color: purple;
    color: #fff;
    width: 100px;
    height: 40px;
    cursor: pointer;
  }
  
  .chat-window {
    width: 350px;
    height: 450px;
    background-color: #f1f1f1;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0; /* Start hidden */
    transform: translateY(20px); /* Start shifted down */
  }
  
  .chat-window.open {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Move to original position */
  }
  
  .chat-header {
    background-color: purple;
    color: white;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-messages {
    text-align: left;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 10px;
    max-width: 80%;
  }
  
  .chat-message.user {
    align-self: flex-end;
    background-color: #007bff;
    color: white;
  }
  
  .chat-message.bot {
    align-self: flex-start;
    background-color: #e2e2e2;
    color: black;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
  }
  
  .chat-input input {
    flex: 1;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-right: 5px;
  }
  
  .chat-input button {
    background-color: purple;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .close-chat {
    background-color: purple;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0 20px;
    cursor: pointer;
    font-size: 25px;
    height: 35px;
    width: auto;
  }
  .typing-indicator {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }
  
  .typing-indicator .dot {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #888;
    border-radius: 50%;
    opacity: 0.6;
    animation: typing 1.5s infinite;
  }
  
  .typing-indicator .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typing-indicator .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .typing-indicator .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes typing {
    0%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-8px);
    }
  }
  
  